window.Vue = require('vue');

var footer = new Vue({
  el: '#footer',
  mounted() {
    var footer_primary = document.querySelector('.footer-primary');
    footer_primary.classList.remove('pull-left');
  } // end of mounted()
});

var reviews = new Vue({
  el: '.wp-google-reviews',
  mounted() {
    console.log('test');    
    var review_wrap = jQuery('.wp-google-review > .wp-google-right');
    // var feedback = review_wrap.find('.wp-google-feedback');
    // jQuery(feedback).prependTo(review_wrap);

    jQuery.each(review_wrap, function(i) {
      //console.log(`review[${i}]`);
      var feedback = jQuery(this).find('.wp-google-feedback');
      feedback.prependTo(jQuery(this));
    });

  } // end of mounted()
});